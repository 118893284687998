<template>
  <div>
    <!------------ START: Variables field ------------>
    <VariablesField
      v-if="isVariablesField"
      v-model="$v.value.$model"
      :field="field"
      :field-wrapper="true"
      :show-toggle="true"
      @toggle-variables-field="toggleVariablesField"
    />
    <!------------ END: Variables field ------------>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper
      v-else
      ref="fieldWrapper"
      :field="field"
      :input-group="true"
      :variables-field-active="variablesFieldActive"
      @toggle-variables-field="toggleVariablesField"
    >
      <!------------ START: Multiselect ------------>
      <v-autocomplete
        v-if="!isVariablesField"
        ref="select"
        v-model="$v.value.$model"
        :items="items"
        item-text="label"
        item-value="value"
        chips
        deletable-chips
        class="form-control h-auto"
        :class="[validationClass, isDisabled ? 'disabled' : '']"
        :clearable="field.clearable"
        clear-icon="fal fa-xmark icon-md"
        append-icon="fal fa-chevron-down icon-md"
        :menu-props="{ offsetY: true }"
        :no-data-text="$t('formHelper.noSelectOptions')"
        :placeholder="getSnippet(field.placeholder)"
        :disabled="isDisabled"
        multiple
        :loading="isLoading || isLoadingDataOptions"
        @focus="onOpen"
        @keydown="search"
      >
        <template v-if="field?.optionsConfig?.async" #no-data>
          <div class="async-options-wrapper">
            <div v-if="isLoadingDataOptions" class="select-loading-wrapper">
              <Loader />

              <div class="alert alert-info">
                {{ $t("formHelper.dataOptions.loading") }}
              </div>
            </div>

            <div v-else class="alert alert-info">
              {{ $t("formHelper.dataOptions.search") }}
            </div>
          </div>
        </template>
      </v-autocomplete>

      <OptionsReloadButton
        v-if="field?.optionsConfig?.async"
        @reload="reloadDataOptions"
      ></OptionsReloadButton>

      <!------------ END: Multiselect ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
    <!------------ START: Error messages tooltip ------------>
    <ErrorsTooltip
      v-if="isMounted"
      :target="$refs.fieldWrapper?.$refs.inputGroup"
      :errors="validationErrors"
    />
    <!------------ END: Error messages tooltip ------------>
  </div>
</template>

<script>
import {
  base,
  eagerValidation,
  select,
  variablesField
} from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import ErrorsTooltip from "@/components/Tools/FormHelper/Components/ErrorsTooltip";
import VariablesField from "@/components/Tools/FormHelper/Components/InputVariables";
import Loader from "@/components/Tools/Loader.vue";
import OptionsReloadButton from "@/components/Tools/FormHelper/Components/OptionsReloadButton.vue";

export default {
  components: {
    OptionsReloadButton,
    Loader,
    FieldWrapper,
    ErrorsTooltip,
    VariablesField
  },
  mixins: [base, select, variablesField, eagerValidation],
  props: {},
  data() {
    return {
      isMounted: false
    };
  },
  computed: {},
  mounted() {
    this.isMounted = true;
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
:deep(.v-select) {
  &.v-select--is-multi {
    .v-select__selections {
      align-items: flex-start;

      span {
        padding: 0 0.35rem !important;
        border-radius: 6px;
        background-color: $color-connect-background;
      }
    }

    input {
      max-height: 32px !important; // Prevent the placeholder from being cut
    }
  }

  .v-chip {
    &.v-size--default {
      height: 25px;
      margin-bottom: 2px;
    }
  }

  .v-ripple__container {
    display: none !important;
  }

  &.v-select--chips {
    .v-select__selections {
      min-height: unset;
    }
  }

  .v-input__slot {
    margin: 0;
    padding: 5px 1rem;
  }

  .v-text-field__details {
    display: none;
  }
}

:deep(.form-control) {
  &.is-invalid,
  &.is-valid {
    background-position: right 0.75rem top 0.6rem;
  }
}

.select-loading-wrapper {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.async-options-wrapper {
  padding: 10px 10px 0;
}
</style>
